export const mineConfig = [
]

export const branchConfig = [
  {
    field: 'selleUserId',
    title: '负责销售',
    type: 'string',
    defaultVal: '',
    componentName: 'GoTree',
    treeType: 'selleUserId'
  }
]

