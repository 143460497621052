import { baseRequest } from './index';

/**
 * 客户管理列表
 * @params custCn string
 * @params selleUserId int
 * @params cooperationStatus int
 * @returns UserInfo
*/
export const custList = (data, aid) => baseRequest.post('/crmapp/customermanagement/list', data, {
  headers: {
    'aid': aid
  }
});

/**
 * 客户详情
 * @params custId int
 * @returns UserInfo
*/
export const custDetail = (data, aid) => baseRequest.post('/crmapp/customermanagement/detail', data, {
  headers: {
    'aid': aid
  }
});

/**
 * 客户编辑
 * @params id int
 * @returns UserInfo
*/
export const custEdit = (id) => baseRequest.post('/crmapp/customermanagement/edit', {id});

/**
 * 客户编辑保存-线索
 * @params data Object
 * @returns MenusInfo
*/
export const custUpdate = (data) => baseRequest.post('/crmapp/customermanagement/update', data);

/**
 * 客户查重
 * @params custCn string
 * @returns UserInfo
*/
export const custSelect = (custCn) => baseRequest.post('/crmapp/customermanagement/custSelect', {custCn});

/**
 * 客户工商校验
 * @params data Object
 * @returns MenusInfo
*/
export const qxbinsert = (data) => baseRequest.post('/crmapp/customermanagement/qxbinsert', data);

/**
 * 客户新增保存
 * @params data Object
 * @returns MenusInfo
*/
export const custSave = (data) => baseRequest.post('/crmapp/customermanagement/save', data);

/**
 * 客户关联线索
 * @params data Object
 * @returns MenusInfo
*/
export const custRelationClueList = (data) => baseRequest.post('/crmapp/clue/custRelationClueList', data);

/**
 * 客户名称变更详情-状态
 * @params data Object
 * @returns MenusInfo
*/
export const custModifyDetail = (id) => baseRequest.post('/crmapp/custModify/detail', {id});

/**
 * 客户名称变更
 * @params data Object
 * @returns MenusInfo
*/
export const custModifyMyCustModify = (data) => baseRequest.post('/crmapp/custModify/myCustModify', data);

/**
 * 客户集团号审批详情
 * @params data Object
 * @returns MenusInfo
*/
export const getProcessResultDetail = (processId) => baseRequest.post('/crmapp/custGroupProcess/getProcessResultDetail', {processId});

