<template>
  <div class="card-box" ref="cardBox">
    <div class="card-cont">
      <div class="card-imgbox" v-if="titleType == 'title-img'" >
        <div class="card-img">
          <img :src="imgUrl" alt="">
        </div>
      </div>
      <div class="card-time" v-if="titleType == 'title-time'">
        <div class="card-data">{{data.time ? data.time : '—/—' }}</div>
        <div class="card-year">{{data.year ? data.year : '——'}}</div>
        <div class="card-line" ></div>
      </div>
      <div class="card-content">
        <div class="card-a">
          <div class="card-aa">
            {{data.title}}
          </div>
          <div class="card-ab" :style="matchColor(data.status)">
            {{data.status}}
          </div>
        </div>
        <div class="card-b"  v-for="(item , index) of data.mainList" :key="index">
          {{item}}
        </div>
        <div class="card-c">
          <div class="cust-ca" v-for="(item , index) of data.tagList" :key="index" >
            {{verdicts(item)}}
          </div>
        </div>
        <slot>
          <div class="card-d" :style="{ marginTop: data.tagList ? '15' + 'px' : '0'}" >
            <div class="card-da">{{data.userTitle}}</div>
            <div class="card-da">{{`${data.branchOrgTitle ? data.branchOrgTitle + '-' : ''}${data.orgTitle ? data.orgTitle : ''}`}}</div>
          </div>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import { verdict } from '@/lib/until.js';
import { matchingColor } from '@/lib/until';

export default {
  props: {
    titleType: {
      type: String,
      default: 'title-img'
    },
    imgUrl: {
      type: String,
      default: require('../../assets/img/company.png')
    },
    data: {
      type: Object
    }
  },
  methods: {
    verdicts(str) {
      return verdict(str);
    },
    matchColor(val) {
      return matchingColor(val);
    }
  }
};
</script>

<style lang="scss" scoped>
.card-box {
  width: 100%;
  // height: 155px;
  margin-top: 10px;
  background-color: $color-FFF;
  .card-cont {
    display: flex;
    justify-content: flex-start;
    padding: 19px 0 15px 14px;
    .card-imgbox {
      width: 45px;
      height: 45px;
      background-color: $color-01;
      border-radius: 50%;
      margin-right: 20px;
      img {
        width: 45px;
        border-radius: 50%;
      }
    }
    .card-time{
      margin-right:25px;
      display: flex;
      flex-direction: column;
      .card-data{
        color:$color-333;
        font-size: 18px;
        margin-bottom: 10px;
      }
      .card-year{
        color:$color-999;
        font-size:$font-12;
        margin-bottom:10px ;
      }
      .card-line{
        flex: 1;
        width: 1px;
        background: $color-E6;
        margin: 0 auto;
      }
    }
    .card-content {
      flex: 1;
      text-align: left;
      .card-a {
        display: flex;
        justify-content: space-between;
        margin-bottom: 9px;
        padding-right: 15px;
        .card-aa {
          color: $color-333;
          font-size: $font-15;
          line-height: 1.5;
          font-weight: 600;
        }
        .card-ab {
          font-size: $font-15;
          color: $color-321;
          font-weight: 600;
          flex: 0 0 60px;
          text-align: right;
          line-height: 1.5;
        }
      }
      .card-b {
        font-size: $font-14;
        color: $color-999;
        line-height:20px;
        text-align: left;
        margin-bottom: 14px;
        padding-right: 15px;
        white-space:normal; // this it is
        word-break:break-all; //or word-wrap:break-word;
      }
      .card-c{
        display: flex;
        justify-content:flex-start;
        padding-right: 14px;
        .cust-ca{
          max-width:180px;
          border-radius: 1px;
          background-color:$color-01;
          color: $color-3FF;
          margin-right: 9px;
          font-size:$font-12;
          padding:6px 6px 4px 6px;
          @extend .uniline-ellipsis;
        }
        .cust-ca:last-child{
          margin-right: 0;
        }
      }
      .card-d {
        margin-top:15px;
        border-top: 1px solid $color-E5;
        display: flex;
        justify-content: space-between;
        .card-da {
          padding-top: 15px;
          color: $color-999;
          font-size: $font-14;
        }
        .card-da:last-child {
          padding-right: 18px;
        }
      }
    }
  }
}
</style>
